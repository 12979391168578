<template>
  <main class="position-relative">
    <template v-if="(Loading === false)">
      <CRow>
        <CCol md="6" col="12">
          <CCard accentColor="info">
            <CCardHeader tag="h5" class="text-info">
              {{ $t('Order.Info') }}
              <div class="card-header-actions d-flex">
                <CButton color="success" type="button" size="sm" class="py-0 px-2 mr-1" @click="Copy(`https://${$store.state.user.permission.Domain}/cart/confirm?id=${Data._id}`)">
                  {{ $t('Order.CopyOrderLink') }}
                </CButton>
                <CButton v-if="!Edit.Info" color="secondary" type="button" size="sm" class="py-0 px-2" @click="EditData('Info')">
                  {{ $t('Global.Edit') }}
                </CButton>
                <template v-if="Edit.Info">
                  <CButton color="secondary" type="button" size="sm" class="py-0 px-2 mr-1" @click="Edit.Info = false">
                    {{ $t('Global.Cancel') }}
                  </CButton>
                  <CButton color="danger" type="button" size="sm" class="py-0 px-2" @click="Update('Info')">
                    {{ $t('Global.Save') }}
                  </CButton>
                </template>
              </div>
            </CCardHeader>
            <CCardBody>
              <CElementCover
                v-if="(Submit === true)"
                :boundaries="[{ sides: ['center', 'center'], query: '#OfferModelSubmit' }]"
                :opacity="0.8"
              >
                <CSpinner color="info"/>
              </CElementCover>
              <div class="mb-3">
                <h6>{{ $t('Order.OrderNum') }}</h6>
                <p>
                  <span class="mr-2">{{ Data.OrderNum }}</span>
                  <a href="#" v-c-tooltip="{content: '複製訂單編號', placement: 'top'}" @click="Copy(Data.OrderNum)" class="text-dark">
                    <i class="far fa-copy" />
                  </a>
                </p>
              </div>
              <div class="mb-3">
                <h6>{{ $t('Order.OrderLink') }}</h6>
                <p>
                  <a :href="`https://${$store.state.user.permission.Domain}/cart/confirm?id=${Data._id}`" class="text-info mr-2" target="_blank">{{ `https://${$store.state.user.permission.Domain}/cart/confirm?id=${Data._id}` }}</a>
                  <a href="#" v-c-tooltip="{content: $t('Order.CopyOrderLink'), placement: 'top'}" @click="Copy(`https://${$store.state.user.permission.Domain}/cart/confirm?id=${Data._id}`)" class="text-dark">
                    <i class="far fa-copy" />
                  </a>
                </p>
              </div>
              <div class="mb-3">
                <h6>{{ $t('Order.CreateTime') }}</h6>
                <p>{{ $dayjs(Data.CreateTime).format('YYYY-MM-DD HH:mm:ss') }}</p>
              </div>
              <div class="mb-3">
                <h6>{{ $t('Order.OrderStatus') }}</h6>
                <p>
                  <CBadge :color="StatusClassOption[Data.Status.Order]">
                    {{ $t('Order.OrderStatusList.' + Data.Status.Order) }}
                  </CBadge>
                </p>
              </div>
              <div v-if="Data.MemberInfo.ReceiverDate" class="mb-3">
                <h6>{{ $t('Order.ReceiverDate') }}</h6>
                <p v-if="!Edit.Info">
                  {{ Data.MemberInfo.ReceiverDate || '--' }}
                </p>
                <v-date-picker v-if="Edit.Info" v-model="UpdateData.Info['MemberInfo.ReceiverDate']" mode="date" :model-config="{type: 'string',mask: 'YYYY-MM-DD'}">
                  <template v-slot="{ inputValue, inputEvents }">
                    <CInput placeholder="YYYY-MM-DD" :value="inputValue" v-on="inputEvents">
                      <template #append-content>
                        <CIcon name="cil-calendar"/>
                      </template>
                    </CInput>
                  </template>
                </v-date-picker>
              </div>
              <CTabs variant="tabs" :active-tab="0" addTabsWrapperClasses="tab-info">
                <CTab :title="$t('Order.CustomNote')">
                  <span v-if="!Edit.Info">
                  {{ Data.Note || '--' }}
                  </span>
                  <CTextarea v-else size="sm" v-model="UpdateData.Info.Note" class="mb-0" />
                </CTab>
                <CTab :title="$t('Order.AdminNote')">
                  <template #title>
                    {{ $t('Order.AdminNote') }} <i v-c-tooltip="{content: '此為管理員的備註，只會顯示於管理員後台內。',placement: 'right'}" class="fas fa-question-circle text-info" />
                  </template>
                  <span v-if="!Edit.Info">
                    {{ Data.Variable.AdminNote || '--' }}
                  </span>
                  <CTextarea v-else size="sm" v-model="UpdateData.Info['Variable.AdminNote']" :description="'此為管理員的備註，只會顯示於管理員後台內。'" class="mb-0" />
                </CTab>
                <CTab>
                  <template #title>
                    {{ $t('Order.ShipmentNote') }} <i v-c-tooltip="{content: '商家備註將會顯示於揀貨單上，供出貨人員查看資訊。',placement: 'right'}" class="fas fa-question-circle text-info" />
                  </template>
                  <span v-if="!Edit.Info">
                    {{ Data.Variable.ShipmentNote || '--' }}
                  </span>
                  <CTextarea v-else size="sm" v-model="UpdateData.Info['Variable.ShipmentNote']" :description="'商家備註將會顯示於揀貨單上，供出貨人員查看資訊。'" class="mb-0" />
                </CTab>
              </CTabs>
            </CCardBody>
          </CCard>
          <CCard accentColor="info">
            <CCardHeader tag="h5" class="text-info">
              {{ $t('Order.BuyerInfo') }}
              <div class="card-header-actions d-flex">
                <CButton color="success" type="button" size="sm" class="py-0 px-2 mr-1" @click="OpenNotifyModal('Custom')">
                  {{ $t('Order.SendNotifyMessage') }}
                </CButton>
                <CButton v-if="NotifyFeatures.includes('SMS')" color="info" type="button" size="sm" class="py-0 px-2 mr-1" @click="OpenSMSModal('Custom')">
                  {{ $t('Order.SendSMSMessage') }}
                </CButton>
                <CButton v-if="!Edit.BuyerInfo" color="secondary" type="button" size="sm" class="py-0 px-2" @click="EditData('BuyerInfo')">
                  {{ $t('Global.Edit') }}
                </CButton>
                <template v-if="Edit.BuyerInfo">
                  <CButton color="secondary" type="button" size="sm" class="py-0 px-2 mr-1" @click="Edit.BuyerInfo = false">
                    {{ $t('Global.Cancel') }}
                  </CButton>
                  <CButton color="danger" type="button" size="sm" class="py-0 px-2" @click="Update('BuyerInfo')">
                    {{ $t('Global.Save') }}
                  </CButton>
                </template>
              </div>
            </CCardHeader>
            <CCardBody>
              <CElementCover
                v-if="(Submit === true)"
                :boundaries="[{ sides: ['center', 'center'], query: '#OfferModelSubmit' }]"
                :opacity="0.8"
              >
                <CSpinner color="info"/>
              </CElementCover>
              <div class="mb-3">
                <h6>{{ $t('Order.Buyer') }}</h6>
                <p v-if="!Edit.BuyerInfo"><CBadge class="mr-1" :color="(Data.UserID === '0' ? 'warning' : 'info')">{{ (Data.UserID === '0' ? '訪客' : '會員') }}</CBadge>{{ Data.MemberInfo.Name }}</p>
                <CInput v-else size="sm" class="mb-3" v-model="UpdateData.BuyerInfo['MemberInfo.Name']" />
              </div>
              <div class="mb-3">
                <h6>{{ $t('Order.Phone') }}</h6>
                <p v-if="!Edit.BuyerInfo">{{ Data.MemberInfo.Phone }}</p>
                <CInput v-else size="sm" class="mb-3" v-model="UpdateData.BuyerInfo['MemberInfo.Phone']" />
              </div>
              <div class="mb-3">
                <h6>{{ $t('Order.Email') }}</h6>
                <p v-if="!Edit.BuyerInfo">{{ Data.MemberInfo.Email }}</p>
                <CInput v-else size="sm" class="mb-3" v-model="UpdateData.BuyerInfo['MemberInfo.Email']" />
              </div>
              <template v-if="Data.Logistics.Type === 'EXPRESS'">
                <div class="mb-3">
                  <h6>{{ $t('Order.City') }}</h6>
                  <p v-if="!Edit.BuyerInfo">{{ Data.MemberInfo.City }}</p>
                  <CInput v-else size="sm" class="mb-3" v-model="UpdateData.BuyerInfo['MemberInfo.City']" />
                </div>
                <div class="mb-3">
                  <h6>{{ $t('Order.Area') }}</h6>
                  <p v-if="!Edit.BuyerInfo">{{ Data.MemberInfo.Area }}</p>
                  <CInput v-else size="sm" class="mb-3" v-model="UpdateData.BuyerInfo['MemberInfo.Area']" />
                </div>
                <div class="mb-3">
                  <h6>{{ $t('Order.Address') }}</h6>
                  <p v-if="!Edit.BuyerInfo">{{ Data.MemberInfo.Address }}</p>
                  <CInput v-else size="sm" class="mb-3" v-model="UpdateData.BuyerInfo['MemberInfo.Address']" />
                </div>
              </template>
            </CCardBody>
          </CCard>
          <CCard accentColor="info">
            <CCardHeader tag="h5" class="text-info">
              {{ $t('Order.LogisticsInfo') }}
              <div class="card-header-actions d-flex">
                <CButton color="success" type="button" size="sm" class="py-0 px-2 mr-1" @click="OpenCreateLogisticsTrackingModal()">
                  {{ $t('Global.Add') + $t('Order.LogisticsTracking.Title') }}
                </CButton>
                <CButton v-if="!Edit.Logistics" color="secondary" type="button" size="sm" class="py-0 px-2" @click="EditData('Logistics')">
                  {{ $t('Global.Edit') }}
                </CButton>
                <template v-if="Edit.Logistics">
                  <CButton color="secondary" type="button" size="sm" class="py-0 px-2 mr-1" @click="Edit.Logistics = false">
                    {{ $t('Global.Cancel') }}
                  </CButton>
                  <CButton color="danger" type="button" size="sm" class="py-0 px-2" @click="UpdateLogistics">
                    {{ $t('Global.Save') }}
                  </CButton>
                </template>
              </div>
            </CCardHeader>
            <CCardBody>
              <CElementCover
                v-if="(Submit === true)"
                :boundaries="[{ sides: ['center', 'center'], query: '#OfferModelSubmit' }]"
                :opacity="0.8"
              >
                <CSpinner color="info"/>
              </CElementCover>
              <div class="mb-3">
                <h6>{{ $t('Order.LogisticsStatus') }}</h6>
                <p v-if="!Edit.Logistics">
                  <CBadge :color="StatusClassOption[Data.Status.Logistics]">
                    {{ $t('Order.LogisticsStatusList.' + Data.Status.Logistics) }}
                  </CBadge>
                </p>
                <CSelect v-else size="sm" :options="LogisticsStatusList" v-model="UpdateData.Logistics['Status.Logistics']" :value.sync="UpdateData.Logistics['Status.Logistics']" :placeholder="$t('Global.PleaseSelect')" />
              </div>
              <div class="mb-3">
                <h6>{{ $t('Order.LogisticsTypeSystem') }}</h6>
                <p v-if="!Edit.Logistics">{{ Data.Logistics.Name }}({{ $t('Logistics.LogisticsSystem.' + Data.Logistics.System) }})</p>
                <CSelect v-else size="sm" :options="LogisticsListOptions" v-model="Data.Logistics.ID" :value.sync="Data.Logistics.ID" :placeholder="$t('Global.PleaseSelect')" @change="ChangeLogistics()" />
              </div>
              <div class="mb-3">
                <h6>{{ $t('Order.LogisticsFee') }}</h6>
                <p v-if="!Edit.Logistics">{{ $store.state.currency }}{{ Data.Logistics.Fee | Currency }}</p>
                <CInput v-else size="sm" class="mb-3" v-model="UpdateData.Logistics['Logistics.Fee']" />
              </div>
              <div class="mb-3">
                <h6>{{ $t('Order.Receiver') }}</h6>
                <p v-if="!Edit.Logistics">{{ Data.MemberInfo.ReceiverName }}</p>
                <CInput v-else size="sm" class="mb-3" v-model="UpdateData.Logistics['MemberInfo.ReceiverName']" />
              </div>
              <div class="mb-3">
                <h6>{{ $t('Order.ReceiverPhone') }}</h6>
                <p v-if="!Edit.Logistics">{{ Data.MemberInfo.ReceiverPhone }}</p>
                <CInput v-else size="sm" class="mb-3" v-model="UpdateData.Logistics['MemberInfo.ReceiverPhone']" />
              </div>
              <div class="mb-3">
                <h6>{{ $t('Order.ReceiverEmail') }}</h6>
                <p v-if="!Edit.Logistics">{{ Data.MemberInfo.ReceiverEmail }}</p>
                <CInput v-else size="sm" class="mb-3" v-model="UpdateData.Logistics['MemberInfo.ReceiverEmail']" />
              </div>
              <template v-if="Data.Logistics.Type === 'EXPRESS'">
                <div class="mb-3">
                  <h6>{{ $t('Order.ReceiverCity') }}</h6>
                  <p v-if="!Edit.Logistics">{{ Data.MemberInfo.ReceiverCity }}</p>
                  <CInput v-else size="sm" class="mb-3" v-model="UpdateData.Logistics['MemberInfo.ReceiverCity']" />
                </div>
                <div class="mb-3">
                  <h6>{{ $t('Order.ReceiverArea') }}</h6>
                  <p v-if="!Edit.Logistics">{{ Data.MemberInfo.ReceiverArea }}</p>
                  <CInput v-else size="sm" class="mb-3" v-model="UpdateData.Logistics['MemberInfo.ReceiverArea']" />
                </div>
                <div class="mb-3">
                  <h6>{{ $t('Order.ReceiverZipCode') }}</h6>
                  <p v-if="!Edit.Logistics">{{ Data.MemberInfo.ReceiverZipCode || '--' }}</p>
                  <CInput v-else size="sm" class="mb-3" v-model="UpdateData.Logistics['MemberInfo.ReceiverZipCode']" />
                </div>
                <div class="mb-3">
                  <h6>{{ $t('Order.ReceiverAddress') }}</h6>
                  <p v-if="!Edit.Logistics">{{ Data.MemberInfo.ReceiverAddress }}</p>
                  <CInput v-else size="sm" class="mb-3" v-model="UpdateData.Logistics['MemberInfo.ReceiverAddress']" />
                </div>
                <div class="mb-3">
                  <h6>{{ $t('Order.ReceiverAddress') }}2</h6>
                  <p v-if="!Edit.Logistics">{{ Data.MemberInfo.ReceiverAddress2 || '--' }}</p>
                  <CInput v-else size="sm" class="mb-3" v-model="UpdateData.Logistics['MemberInfo.ReceiverAddress2']" />
                </div>
                <div class="mb-3">
                  <h6>{{ $t('Order.ReceiverAddress') }}3</h6>
                  <p v-if="!Edit.Logistics">{{ Data.MemberInfo.ReceiverAddress3 || '--' }}</p>
                  <CInput v-else size="sm" class="mb-3" v-model="UpdateData.Logistics['MemberInfo.ReceiverAddress3']" />
                </div>
                <template v-if="Array.isArray(Data.MemberInfo.AdditionalReceiver)">
                  <CCard v-for="(AdditionalReceiver, Index) in Data.MemberInfo.AdditionalReceiver" :key="Index" class="mb-2">
                    <CButton
                      block
                      color="secondary"
                      size="sm"
                      class="text-left shadow-none card-header bg-secondary border-secondary"
                      @click="(AdditionalReceiverAccordion = AdditionalReceiverAccordion === Index ? false : Index)"
                    >
                      <h6 class="m-0"><i class="fas fa-box mr-2" />額外收件人{{ Index + 1 }}</h6>
                    </CButton>
                    <CCollapse :show="AdditionalReceiverAccordion === Index">
                      <CCardBody>
                        <div class="mb-3">
                          <h6>{{ $t('Order.Receiver') }}</h6>
                          <p>{{ AdditionalReceiver.ReceiverName }}</p>
                        </div>
                        <div class="mb-3">
                          <h6>{{ $t('Order.ReceiverPhone') }}</h6>
                          <p>{{ AdditionalReceiver.ReceiverPhone }}</p>
                        </div>
                        <div>
                          <h6>{{ $t('Order.ReceiverEmail') }}</h6>
                          <p>{{ AdditionalReceiver.ReceiverEmail }}</p>
                        </div>
                      </CCardBody>
                    </CCollapse>
                  </CCard>
                </template>
              </template>
              <template v-if="Data.Logistics.Type === 'SUPERSTORE'">
                <div class="mb-3">
                  <h6>{{ $t('Order.StoreID') }}</h6>
                  <p v-if="!Edit.Logistics">{{ Data.Logistics.Detail ? Data.Logistics.Detail.StoreID : '--' }}</p>
                  <CInput v-else size="sm" class="mb-3" v-model="UpdateData.Logistics['Logistics.Detail.StoreID']" />
                </div>
                <div class="mb-3">
                  <h6>{{ $t('Order.StoreName') }}</h6>
                  <p v-if="!Edit.Logistics">{{ Data.Logistics.Detail ? Data.Logistics.Detail.StoreName : '--' }}</p>
                  <CInput v-else size="sm" class="mb-3" v-model="UpdateData.Logistics['Logistics.Detail.StoreName']" />
                </div>
              </template>
              <template v-if="Data.Logistics.Type === 'SELF'">
                <div class="mb-3">
                  <h6>{{ $t('Order.StoreName') }}</h6>
                  <p v-if="!Edit.Logistics">{{ Data.Logistics.Detail ? Data.Logistics.Detail.StoreName : '--' }}</p>
                  <CInput v-else size="sm" class="mb-3" v-model="UpdateData.Logistics['Logistics.Detail.StoreName']" />
                </div>
                <div class="mb-3">
                  <h6>{{ $t('Order.StoreAddress') }}</h6>
                  <p v-if="!Edit.Logistics">{{ Data.Logistics.Detail ? Data.Logistics.Detail.StoreAddress : '--' }}</p>
                  <CInput v-else size="sm" class="mb-3" v-model="UpdateData.Logistics['Logistics.Detail.StoreAddress']" />
                </div>
              </template>
            </CCardBody>
          </CCard>
          <CCard accentColor="info">
            <CCardHeader tag="h5" class="text-info">
              {{ $t('Order.PaymentInfo') }}
              <div class="card-header-actions d-flex">
                <CButton v-if="false" color="danger" type="button" size="sm" class="py-0 px-2 mr-1">
                  {{ $t('Order.Refund') }}
                </CButton>
                <CButton v-if="Data.Status.Payment === 'U'" color="warning" type="button" size="sm" class="py-0 px-2 mr-1" @click="OpenNotifyModal('Unpaid')">
                  {{ $t('Order.NotifyPayment') }}
                </CButton>
                <CButton v-if="!Edit.Payment" color="secondary" type="button" size="sm" class="py-0 px-2" @click="EditData('Payment')">
                  {{ $t('Global.Edit') }}
                </CButton>
                <template v-if="Edit.Payment">
                  <CButton color="secondary" type="button" size="sm" class="py-0 px-2 mr-1" @click="Edit.Payment = false">
                    {{ $t('Global.Cancel') }}
                  </CButton>
                  <CButton color="danger" type="button" size="sm" class="py-0 px-2" @click="UpdatePayment">
                    {{ $t('Global.Save') }}
                  </CButton>
                </template>
              </div>
            </CCardHeader>
            <CCardBody>
              <CElementCover
                v-if="(Submit === true)"
                :boundaries="[{ sides: ['center', 'center'], query: '#OfferModelSubmit' }]"
                :opacity="0.8"
              >
                <CSpinner color="info"/>
              </CElementCover>
              <div class="mb-3">
                <h6>{{ $t('Order.PaymentStatus') }}</h6>
                <p v-if="!Edit.Payment">
                  <CBadge :color="StatusClassOption[Data.Status.Payment]">
                    {{ $t('Order.PaymentStatusList.' + Data.Status.Payment) }}
                  </CBadge>
                </p>
                <CSelect v-else size="sm" :options="PaymentStatusList" v-model="UpdateData.Payment['Status.Payment']" :value.sync="UpdateData.Payment['Status.Payment']" :placeholder="$t('Global.PleaseSelect')" />
              </div>
              <div class="mb-3">
                <h6>{{ $t('Order.PaymentType') }}</h6>
                <p v-if="!Edit.Payment">{{ Data.Payment.Name }}({{ $t('Payment.PaymentSystem.' + Data.Payment.System) }})</p>
                <CSelect v-else size="sm" :options="PaymentListOptions" v-model="Data.Payment.ID" :value.sync="Data.Payment.ID" :placeholder="$t('Global.PleaseSelect')" @change="ChangePayment()" />
              </div>
              <div class="mb-3">
                <h6>{{ $t('Order.PaymentFee') }}</h6>
                <p v-if="!Edit.Payment">
                  {{ $store.state.currency }}{{ Data.Payment.Fee | Currency }}
                </p>
                <CInput v-else size="sm" v-model="UpdateData.Payment['Payment.Fee']" />
              </div>
              <div v-if="Data.Payment.Detail && Data.Payment.Detail.TransactionOrderNo" class="mb-3">
                <h6>{{ $t('Order.TransactionOrderNo') }}</h6>
                <p>
                  {{ Data.Payment.Detail && Data.Payment.Detail.TransactionOrderNo }}
                </p>
              </div>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol md="6" col="12">
          <CCard accentColor="info">
            <CCardHeader tag="h5" class="text-info">
              {{ $t('Order.InvoiceInfo') }}
              <div class="card-header-actions d-flex">
                <CButton v-if="!Data.Invoice.Number" color="success" type="button" size="sm" class="py-0 px-2 mr-1" @click="IssueInvoice()">
                  {{ $t('Order.IssueInvoice') }}
                </CButton>
                <CButton v-if="!Edit.Invoice" color="secondary" type="button" size="sm" class="py-0 px-2" @click="EditData('Invoice')">
                  {{ $t("Global.Edit") }}
                </CButton>
                <template v-if="Edit.Invoice">
                  <CButton color="secondary" type="button" size="sm" class="py-0 px-2 mr-1" @click="Edit.Invoice = false">
                    {{ $t("Global.Cancel") }}
                  </CButton>
                  <CButton color="danger" type="button" size="sm" class="py-0 px-2" @click="Update('Invoice')">
                    {{ $t("Global.Save") }}
                  </CButton>
                </template>
              </div>
            </CCardHeader>
            <CCardBody>
              <CElementCover
                v-if="(Submit === true)"
                :boundaries="[{ sides: ['center', 'center'], query: '#OfferModelSubmit' }]"
                :opacity="0.8"
              >
                <CSpinner color="info"/>
              </CElementCover>
              <CAlert v-if="!Data.Invoice.Number" color="info">
                <i class="fas fa-info-circle mr-2" />尚未開立發票，您可以自行手動開立發票或是於<b>「商店設定 > 購物設定」</b>內開啟自動開立發票功能。
              </CAlert>
              <div class="mb-3">
                <h6>{{ $t('Order.Invoice.Type') }}</h6>
                <p v-if="!Edit.Invoice">{{ $t("Order.InvoiceType." + Data.Invoice.Type) }}</p>
                <CSelect v-else size="sm" :options="InvoiceTypeOptions" v-model="UpdateData.Invoice['Invoice.Type']" :value.sync="UpdateData.Invoice['Invoice.Type']" :placeholder="$t('Global.PleaseSelect')" @change="CheckInvoiceType" />
              </div>
              <div class="mb-3">
                <h6>{{ $t('Order.Invoice.Carrier') }}</h6>
                <p v-if="!Edit.Invoice">{{ Data.Invoice.Carrier || "--" }}</p>
                <CInput v-else type="text" size="sm" v-model="UpdateData.Invoice['Invoice.Carrier']" />
              </div>
              <template v-if="Data.Invoice.Type === 'COM'">
                <div class="mb-3">
                  <h6>{{ $t('Order.Invoice.Header') }}</h6>
                  <p v-if="!Edit.Invoice">{{ Data.Invoice.Header || "--" }}</p>
                  <CInput v-else type="text" size="sm" v-model="UpdateData.Invoice['Invoice.Header']" />
                </div>
                <div class="mb-3">
                  <h6>{{ $t('Order.Invoice.UniCode') }}</h6>
                  <p v-if="!Edit.Invoice">{{ Data.Invoice.UniCode || "--" }}</p>
                  <CInput v-else type="text" size="sm" v-model="UpdateData.Invoice['Invoice.UniCode']" />
                </div>
                <div class="mb-3">
                  <h6>{{ $t('Order.Invoice.Address') }}</h6>
                  <p v-if="!Edit.Invoice">{{ Data.Invoice.Address || "--" }}</p>
                  <CInput v-else type="text" size="sm" v-model="UpdateData.Invoice['Invoice.Address']" />
                </div>
              </template>
              <template v-if="Data.Invoice.Number">
                <div class="mb-3">
                  <div class="mb-3">
                    <h6>{{ $t('Order.Invoice.IssueDate') }}</h6>
                    <p>{{ Data.Invoice.IssueDate || '--' }}</p>
                  </div>
                  <div class="mb-3">
                    <h6>{{ $t('Order.Invoice.Number') }}</h6>
                    <p>{{ Data.Invoice.Number || '--' }}</p>
                  </div>
                  <h6>{{ $t('Order.Invoice.RandomNumber') }}</h6>
                  <p>{{ Data.Invoice.RandomNumber || '--' }}</p>
                </div>
              </template>
            </CCardBody>
          </CCard>
          <CCard accentColor="info">
            <CCardHeader tag="h5" class="text-info">
              {{ $t('Order.ItemList') }}
              <div class="card-header-actions d-flex">
                <CDropdown size="sm" color="success" placement="bottom-start" add-toggler-classes="py-0 px-2 mr-1">
                  <template #toggler-content>
                    <CIcon size="sm" name="cil-plus" class="mr-1" />{{ $t('Navigation.Products/Add') }}
                  </template>
                  <CDropdownItem @click="OpenProductListModal('Default')">
                    {{ $t('Product.TaxonomyOptions.Default') }}
                  </CDropdownItem>
                  <CDropdownItem @click="OpenProductListModal('Gift')">
                    {{ $t('Product.TaxonomyOptions.Gift') }}
                  </CDropdownItem>
                  <CDropdownItem @click="OpenProductListModal('ExtraItems')">
                    {{ $t('Product.TaxonomyOptions.ExtraItems') }}
                  </CDropdownItem>
                </CDropdown>
                <CButton color="info" type="button" size="sm" class="py-0 px-2 mr-1" @click="OpenOfferModal()">
                  <i class="fas fa-percent mr-1 fa-xs" />{{ $t('Order.AddOffer') }}
                </CButton>
                <CButton  v-if="!Edit.ProductInfo" color="secondary" type="button" size="sm" class="py-0 px-2 mr-1" @click="EditData('ProductInfo')">
                  {{ $t('Global.Edit') }}
                </CButton>
                <template v-if="Edit.ProductInfo">
                  <CButton color="secondary" type="button" size="sm" class="py-0 px-2 mr-1" @click="Edit.ProductInfo = false">
                    {{ $t('Global.Cancel') }}
                  </CButton>
                  <CButton color="danger" type="button" size="sm" class="py-0 px-2" @click="UpdateProductInfo()">
                    {{ $t('Global.Save') }}
                  </CButton>
                </template>
              </div>
            </CCardHeader>
            <CCardBody class="p-0">
              <CDataTable
                :loading="Loading || Submit"
                :items="Data.ProductInfo"
                :fields="ProductListField"
                addTableClasses="mb-0"
                responsive
                hover
              >
                <template #Cover="{item}">
                  <td>
                    <img v-lazy="item.Cover" width="60" height="60" class="img-fluid"/>
                  </td>
                </template>
                <template #Name="{item}">
                  <td>
                    <span v-if="item.Taxonomy === 'Gift'" class="badge badge-dark">{{ $t('Offer.Gift') }}</span>
                    {{ item.Name }}<br>
                    <CBadge class="mr-1" v-if="(Data.UserID !== '0' && item.Discount < 1)" color="info">{{ `會員折扣${item.Discount * 100}折` }}</CBadge>
                    <template v-if="item.EnableOption">
                      <div>
                        <small class="smaller p-text-secondary"><span
                          class="p-text-bold">{{ $t('Order.ItemSpecification') }}: </span><span
                          class="p-d-block p-d-sm-inline">{{
                            item.SelectOptionsSource.map(item => item.OptionName).join('、')
                          }}</span></small>
                      </div>
                    </template>
                  </td>
                </template>
                <template #Quantity="{item, index}">
                  <td>
                    <template v-if="!Edit.ProductInfo">
                      {{ item.Quantity }}
                    </template>
                    <CInput v-else type="number" size="sm" v-model="UpdateData.ProductInfo.ProductInfo[index].Quantity" />
                  </td>
                </template>
                <template #Amount="{item, index}">
                  <td>
                    <span v-if="item.Taxonomy === 'Gift'">
                      {{ $t('Offer.Gift') }}
                    </span>
                    <span v-else>
                      <template v-if="!Edit.ProductInfo">
                        <span class="font-weight-bold text-danger">{{ $store.state.currency }}{{ $amount.format(Math.round(item.Amount.Actual * item.Discount)) | Currency }}</span>
                        <small v-if="(Data.UserID !== '0' && item.Discount < 1)" class="d-block">(原價: {{ $store.state.currency }}{{ $amount.format(Math.round(item.Amount.Actual)) | Currency }})</small>
                      </template>
                      <CInput v-else type="number" size="sm" v-model="UpdateData.ProductInfo.ProductInfo[index].Amount.Actual" />
                    </span>
                  </td>
                </template>
                <template #Action="{index}">
                  <td class="text-center">
                    <CButton color="danger" variant="ghost" shape="pill" size="sm" @click="DeleteProductItem(index)">
                      <CIcon name="cil-trash" class="c-icon-custom-size"/>
                    </CButton>
                  </td>
                </template>
                <template #footer>
                  <tr>
                    <td class="font-weight-bold py-1 pt-2">
                      {{ $t('Order.ProductInfo.ProductTotal') }}
                    </td>
                    <td colspan="4" class="py-1 pt-2" />
                    <td class="py-1 pt-2">
                      {{ $store.state.currency }}{{ $amount.format(Data.Amount) | Currency }}
                    </td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold border-0 py-1">
                      {{ $t('Order.OfferAmount') }}
                    </td>
                    <td colspan="4" class="border-0 py-1 pb-2" />
                    <td class="border-0 py-1 pb-2">
                      <span v-if="Data.Offer.length === 0">{{ $store.state.currency }}{{ $amount.format(0 - Data.OfferAmount) | Currency }}</span>
                      <a v-else href="javaScript:void(0)" @click="OpenOfferModal()">{{ $store.state.currency }}{{ $amount.format(0 - Data.OfferAmount) | Currency }}</a>
                    </td>
                  </tr>
                  <tr v-if="Data.Wallet">
                    <td class="font-weight-bold border-0 py-1">
                      {{ $t('Order.BonusAmount') }}
                    </td>
                    <td colspan="4" class="border-0 py-1 pb-2" />
                    <td class="border-0 py-1 pb-2">
                      {{ $store.state.currency }}{{ $amount.format(0 - parseInt(Data.Wallet.Bonus.Value)) | Currency }}
                    </td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold border-0 py-1">
                      {{ $t('Order.ProductInfo.SubTotal') }} <i v-c-tooltip="{content: $t('Order.ProductInfo.SubTotalInfo'),placement: 'right'}" class="fas fa-question-circle text-info" />
                    </td>
                    <td colspan="4" class="border-0 py-1" />
                    <td class="border-0 py-1">
                      {{ $store.state.currency }}{{ $amount.format(Data.RealAmount) | Currency }}
                    </td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold border-0 py-1">
                      {{ $t('Order.LogisticsFee') }}
                    </td>
                    <td colspan="4" class="border-0 py-1" />
                    <td class="border-0 py-1">
                      {{ $store.state.currency }}{{ $amount.format(Data.Logistics.Fee) | Currency }}
                    </td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold border-0 py-1 pb-2">
                      {{ $t('Order.PaymentFee') }}
                    </td>
                    <td colspan="4" class="border-0 py-1 pb-2" />
                    <td class="border-0 py-1 pb-2">
                      {{ $store.state.currency }}{{ $amount.format(Data.Payment.Fee) | Currency }}
                    </td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold pt-2">
                      {{ $t('Order.ProductInfo.Total') }}
                    </td>
                    <td colspan="4" class="pt-2" />
                    <td class="font-weight-bold pt-2">
                      {{ $store.state.currency }}{{ $amount.format(Data.TotalAmount) | Currency }}
                    </td>
                  </tr>
                </template>
              </CDataTable>
            </CCardBody>
          </CCard>
          <CCard v-if="Data.LogisticsData || (Data.LogisticsPackage && Data.LogisticsPackage.length > 0)" accentColor="info">
            <CCardHeader tag="h5" class="text-info">{{ $t('Order.LogisticsTracking.Title') }}</CCardHeader>
            <CCardBody v-if="(Data.LogisticsPackage && Data.LogisticsPackage.length > 1)">
              <CAlert color="info">
                <i class="fas fa-info-circle mr-2" />目前有多個物流追蹤貨件，點選以查看各貨件的物流追蹤詳情。
              </CAlert>
              <template v-for="(LogisticsPackage, Index) in Data.LogisticsPackage">
                <CCard :key="Index" class="mb-2">
                  <CButton
                    block
                    color="secondary"
                    size="sm"
                    class="text-left shadow-none card-header bg-secondary border-secondary"
                    @click="(LogisticsPackageAccordion = LogisticsPackageAccordion === Index ? false : Index)"
                  >
                    <h6 class="m-0"><i class="fas fa-box mr-2" />貨件{{ Index + 1 }}</h6>
                  </CButton>
                  <CCollapse :show="LogisticsPackageAccordion === Index">
                    <CCardBody>
                      <div v-if="Data.Logistics.System === 'CUSTOM'" class="mb-3">
                        <h6>{{ $t('Order.LogisticsTracking.Provider') }}</h6>
                        <p>
                          <a :href="LogisticsPackage.TrackURL" target="_blank">
                            {{ LogisticsPackage.Method }}
                          </a>
                        </p>
                      </div>
                      <div v-if="Data.Logistics.System !== 'CUSTOM'" class="mb-3">
                        <h6>{{ $t('Order.LogisticsSystem') }}</h6>
                        <p>
                          <a :href="LogisticsPackage.TrackURL" target="_blank">
                            {{ $t('Logistics.LogisticsSystem.' + Data.Logistics.System) }}
                          </a>
                        </p>
                      </div>
                      <div class="mb-3">
                        <h6>{{ $t('Order.LogisticsTracking.TrackingNum') }}</h6>
                        <p>
                          {{ LogisticsPackage.BookingNo }}
                        </p>
                      </div>
                      <div class="mb-3">
                        <h6>{{ $t('Logistics.ShippingDate') }}</h6>
                        <p>
                          {{ LogisticsPackage.ShippingDate || '--' }}
                        </p>
                      </div>
                      <div v-if="LogisticsPackage.AssignDate" class="mb-3">
                        <h6>{{ $t('Logistics.AssignDate') }}</h6>
                        <p>
                          {{ LogisticsPackage.AssignDate || '--' }}
                        </p>
                      </div>
                    </CCardBody>
                  </CCollapse>
                </CCard>
              </template>
            </CCardBody>
            <CCardBody v-else>
              <div v-if="Data.Logistics.System === 'CUSTOM'" class="mb-3">
                <h6>{{ $t('Order.LogisticsTracking.Provider') }}</h6>
                <p>
                  <a :href="Data.LogisticsData.TrackURL" target="_blank">
                    {{ Data.LogisticsData.Method }}
                  </a>
                </p>
              </div>
              <div v-if="Data.Logistics.System !== 'CUSTOM'" class="mb-3">
                <h6>{{ $t('Order.LogisticsSystem') }}</h6>
                <p>
                  <a :href="Data.LogisticsData.TrackURL" target="_blank">
                    {{ $t('Logistics.LogisticsSystem.' + Data.Logistics.System) }}
                  </a>
                </p>
              </div>
              <div class="mb-3">
                <h6>{{ $t('Order.LogisticsTracking.TrackingNum') }}</h6>
                <p>
                  {{ Data.LogisticsData.BookingNo }}
                </p>
              </div>
              <div class="mb-3">
                <h6>{{ $t('Logistics.ShippingDate') }}</h6>
                <p>
                  {{ Data.LogisticsData.ShippingDate || '--' }}
                </p>
              </div>
              <div v-if="Data.LogisticsData.AssignDate && Data.LogisticsData.AssignDate !== 'Invalid Date'" class="mb-3">
                <h6>{{ $t('Logistics.AssignDate') }}</h6>
                <p>
                  {{ Data.LogisticsData.AssignDate || '--' }}
                </p>
              </div>
            </CCardBody>
          </CCard>
          <CCard v-if="LogisticsFeatures.includes('Packing')" accentColor="info">
            <CCardHeader tag="h5" class="text-info">{{ $t('Order.Packing') }}<i v-c-tooltip="{content: '系統會根據「包裹裝箱設定」所設定的包裹材積計算出可放入的商品，您的商品同時也必須設定材積，否則將無法計算。',placement: 'right'}" class="fas fa-question-circle text-info ml-2" /></CCardHeader>
            <CCardBody>
              <CAlert v-if="!Data.Packing.FinishPacked" color="warning">
                <i class="fas fa-info-circle mr-2" />系統未完成包裝，因為有商品已超過所有箱子的大小
              </CAlert>
              <CCard v-for="(item, key) in Data.Packing.Packages" :key="key">
                <CButton
                  block
                  color="secondary"
                  size="sm"
                  class="text-left shadow-none card-header bg-secondary border-secondary"
                  @click="(Accordion = Accordion === key ? false : key)"
                >
                  <h6 class="m-0">{{ item.CurrentPacking.Name }}({{ item.CurrentPacking.Length }} x {{ item.CurrentPacking.Width }} x {{ item.CurrentPacking.Height }} CM)</h6>
                </CButton>
                <CCollapse :show="Accordion === key">
                  <CCardBody>
                    <CDataTable
                      :loading="Loading"
                      :items="item.Items"
                      :fields="ProductPackageListField"
                      addTableClasses="mb-0"
                      responsive
                      hover
                    >
                      <template #Cover="{item}">
                        <td>
                          <img v-lazy="item.Cover" width="60" height="60" class="img-fluid"/>
                        </td>
                      </template>
                      <template #Name="{item}">
                        <td>
                          <p class="mb-2"><span v-if="item.Taxonomy === 'Gift'" class="badge badge-dark">{{ $t('Offer.Gift') }}</span>{{ item.Name }}</p>
                          <p class="small mb-0"><b>{{ $t('Product/Detail.Length') }}:</b> {{ item.Variable.Length }} CM</p>
                          <p class="small mb-0"><b>{{ $t('Product/Detail.Width') }}:</b> {{ item.Variable.Width }} CM</p>
                          <p class="small mb-0"><b>{{ $t('Product/Detail.Height') }}:</b> {{ item.Variable.Height }} CM</p>
                          <p class="small mb-0"><b>{{ $t('Product/Detail.Weight') }}:</b> {{ item.Variable.Weight }} KG</p>
                        </td>
                      </template>
                      <template #Quantity>
                        <td>1</td>
                      </template>
                      <template #Amount="{item}">
                        <td>
                          <span v-if="item.Taxonomy === 'Gift'">{{ $t('Offer.Gift') }}</span>
                          <span v-else>{{ $store.state.currency }}{{ item.Amount.Actual | Currency }}</span>
                        </td>
                      </template>
                    </CDataTable>
                  </CCardBody>
                </CCollapse>
              </CCard>
              <CCard v-if="UnpackedProductItems.length > 0">
                <CButton
                  block
                  color="secondary"
                  size="sm"
                  class="text-left shadow-none card-header bg-secondary border-secondary"
                  @click="(Accordion = Accordion === (Data.Packing.Packages.length) ? false : (Data.Packing.Packages.length))"
                >
                  <h6 class="m-0">未包裝或無法包裝</h6>
                </CButton>
                <CCollapse :show="Accordion === (Data.Packing.Packages.length)">
                  <CCardBody>
                    <CDataTable
                      :loading="Loading"
                      :items="UnpackedProductItems"
                      :fields="ProductPackageListField"
                      addTableClasses="mb-0"
                      responsive
                      hover
                    >
                      <template #Cover="{item}">
                        <td>
                          <img v-lazy="item.Cover" width="60" height="60" class="img-fluid"/>
                        </td>
                      </template>
                      <template #Name="{item}">
                        <td>
                          <p class="mb-2"><span v-if="item.Taxonomy === 'Gift'" class="badge badge-dark">{{ $t('Offer.Gift') }}</span>{{ item.Name }}</p>
                          <p class="small mb-0"><b>{{ $t('Product/Detail.Length') }}:</b> {{ item.Variable.Length }} CM</p>
                          <p class="small mb-0"><b>{{ $t('Product/Detail.Width') }}:</b> {{ item.Variable.Width }} CM</p>
                          <p class="small mb-0"><b>{{ $t('Product/Detail.Height') }}:</b> {{ item.Variable.Height }} CM</p>
                          <p class="small mb-0"><b>{{ $t('Product/Detail.Weight') }}:</b> {{ item.Variable.Weight }} KG</p>
                        </td>
                      </template>
                      <template #Quantity>
                        <td>1</td>
                      </template>
                      <template #Amount="{item}">
                        <td>
                          <span v-if="item.Taxonomy === 'Gift'">{{ $t('Offer.Gift') }}</span>
                          <span v-else>{{ $store.state.currency }}{{ item.Amount.Actual | Currency }} </span>
                        </td>
                      </template>
                    </CDataTable>
                  </CCardBody>
                </CCollapse>
              </CCard>
            </CCardBody>
          </CCard>
          <CCard v-if="Features.includes('Message')" accentColor="info">
            <CCardHeader tag="h5" class="text-info">
              {{ $t('Order.OrderCustomerMessage') }}
              <div class="card-header-actions d-flex">
                <CButton v-if="!Edit.Message" color="info" type="button" size="sm" class="py-0 px-2" @click="EditData('Message')">
                  新增回覆
                </CButton>
                <CButton v-if="!Edit.Message && (Data.Message && Data.Message.length > 0)" :color="(Data.Variable.MessageProcessed === true ? 'success' : 'secondary')" type="button" size="sm" class="py-0 px-2 ml-1" @click="EditData('Processed')">
                  <CIcon v-if="Data.Variable.MessageProcessed === true" name="cil-check-circle" class="c-icon-custom-size mr-1" />{{ Data.Variable.MessageProcessed === true ? $t('Order.Message.Processed') : $t('Order.Message.DoProcessed') }}
                </CButton>
                <template v-if="Edit.Message">
                  <CButton color="secondary" type="button" size="sm" class="py-0 px-2 mr-1" @click="Edit.Message = false">
                    {{ $t('Global.Cancel') }}
                  </CButton>
                  <CButton color="danger" type="button" size="sm" class="py-0 px-2" @click="Update('Message')">
                    {{ $t('Global.Save') }}
                  </CButton>
                </template>
              </div>
            </CCardHeader>
            <CCardBody>
              <CElementCover
                v-if="(Submit === true)"
                :boundaries="[{ sides: ['center', 'center'], query: '#OfferModelSubmit' }]"
                :opacity="0.8"
              >
                <CSpinner color="info"/>
              </CElementCover>
              <CAlert v-if="!Data.Message || Data.Message.length === 0" color="info">
                {{ $t('Order.NoOrderCustomerMessage') }}
              </CAlert>
              <div class="MessageContent">
                <div v-if="Edit.Message || Data.Message.length > 0" class="vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
                  <div v-if="Edit.Message" class="vertical-timeline-item vertical-timeline-element">
                    <div>
                    <span class="vertical-timeline-element-icon bounce-in">
                      <i class="badge badge-dot badge-dot-xl badge-warning" />
                    </span>
                      <div class="vertical-timeline-element-content alert-warning p-3 rounded bounce-in">
                        <h5 class="timeline-title">新增留言</h5>
                        <Editor api-key="4nx1b5861rrn2x3f6nmpcduhkmi09comjciy0cw6inllfbwk" :init="TinyMCE" v-model="UpdateData.Message.CustomMessage.Content" />
                      </div>
                    </div>
                  </div>
                  <div v-for="(Message, Index) in Data.Message" :key="$dayjs(Message.CreateTime).unix()" class="vertical-timeline-item vertical-timeline-element">
                    <div>
                    <span class="vertical-timeline-element-icon bounce-in">
                      <i class="badge badge-dot badge-dot-xl" :class="[{ 'badge-info': Message.Origin === 'Client', 'badge-success': Message.Origin === 'Manage' }]"></i>
                    </span>
                      <div class="vertical-timeline-element-content p-3 rounded bounce-in" :class="[{ 'alert-info': Message.Origin === 'Client', 'alert-success': Message.Origin === 'Manage' }]">
                       <div class="d-flex justify-content-between">
                         <h5 class="timeline-title">{{ $t('Order.Message.' + Message.Origin) }}</h5>
                         <CButton size="sm" color="danger" shape="pill" class="mr-2" @click="RemoveMessage(Index)">
                           <CIcon size="sm" name="cil-x"/>
                         </CButton>
                       </div>
                        <div class="mb-3" v-html="Message.Content" />
                        <p class="small font-italic text-right mb-0">
                          <span v-if="Message.Manager && Message.Manager.Name">by {{ Message.Manager.Name }}({{ Message.Manager.Email }})</span>
                          <br>
                          <span>{{ $t('Order.ActivityLogEvent.SystemTime') }}: {{ $dayjs(Message.CreateTime).format('YYYY-MM-DD HH:mm:ss') }}</span>
                        </p>
                        <span class="vertical-timeline-element-date">{{ $dayjs(Message.EventTime).format('MMM-DD') }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CCardBody>
          </CCard>
          <CCard accentColor="info">
            <CCardHeader tag="h5" class="text-info">{{ $t('Order.OrderLog') }}</CCardHeader>
            <CCardBody>
              <OrderLog :OrderData="Data" />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </template>
    <CElementCover v-if="(Loading === true)" :opacity="0.75" class="py-5 bg-transparent">
      <CSpinner color="info"/>
    </CElementCover>
    <component
      :is="Component"
      :Single="true"
      :Toggle.sync="ToggleModal"
      :MemberInfo="Data.MemberInfo"
      :MessageType.sync="MessageType"
      :OrderNum.sync="SelectOrderNum"
      :OrderData.sync="Data"
      :ChooseProductType="ChooseProductType"
      @ChooseProduct="UpdateProductInfo"
    />
    <CModal id="OfferModel" class="ActionModel" size="lg" :show.sync="OfferModel" :centered="true" :closeOnBackdrop="!Submit">
      <CDataTable
        v-if="OfferModel"
        :loading="Loading"
        :items.sync="UpdateData.Offer.Offer"
        :fields="OfferListField"
        addTableClasses="mb-0"
        responsive
        hover
      >
        <template #Name="{item}">
          <td>
            <CInput v-if="item.Editing === true" v-model="item.Name" class="mb-0" />
            <div v-else class="text-truncate" style="max-width: 360px;">
              {{ item.Name }}
            </div>
          </td>
        </template>
        <template #Taxonomy="{item}">
          <td>
            {{ $t('Offer.Taxonomy.' + item.Taxonomy) }}
          </td>
        </template>
        <template #OfferAmount="{item}">
          <td>
            <CInput v-if="item.Editing === true" v-model="item.CurrentOfferAmount" class="mb-0" type="number" />
            <span v-else>{{ $store.state.currency }}{{ -item.CurrentOfferAmount | Currency }}</span>
          </td>
        </template>
        <template #Action="{item, index}">
          <td>
            <template v-if="item.Taxonomy === 'CUSTOM'">
              <CButton v-if="!item.Editing" color="info" variant="ghost" shape="pill" size="sm" @click="item.Editing = true">
                <CIcon name="cil-pencil" class="c-icon-custom-size"/>
              </CButton>
              <CButton v-if="item.Editing" color="success" variant="ghost" shape="pill" size="sm" @click="item.Editing = false">
                <CIcon name="cil-check-circle" class="c-icon-custom-size"/>
              </CButton>
            </template>
            <CButton color="danger" variant="ghost" shape="pill" size="sm" @click="UpdateData.Offer.Offer.splice(index, 1)">
              <CIcon name="cil-trash" class="c-icon-custom-size"/>
            </CButton>
          </td>
        </template>
        <template #footer>
          <tr>
            <td class="font-weight-bold pt-2">
              {{ $t('Order.ProductInfo.Total') }}
            </td>
            <td>
            </td>
            <td class="font-weight-bold pt-2">
              {{ $store.state.currency }}{{ UpdateData.Offer.OfferAmount * -1 | Currency }}
            </td>
            <td>
            </td>
          </tr>
        </template>
      </CDataTable>
      <template #header-wrapper>
        <header class="modal-header border-bottom-0">
          <h5 class="modal-title">{{ $t('Order.OfferInfo') }}</h5>
          <CButtonClose @click="OfferModel = false"/>
        </header>
      </template>
      <template #footer-wrapper>
        <CElementCover
          v-if="(Submit === true)"
          :boundaries="[{ sides: ['center', 'center'], query: '#OfferModelSubmit' }]"
          :opacity="0.8"
        >
          <CSpinner size="sm" color="primary"/>
        </CElementCover>
        <footer class="modal-footer border-top-0">
          <CButton @click="AddOffer()" color="info">
            <CIcon size="sm" name="cil-plus" class="mr-1" />{{ $t('Order.AddOffer') }}
          </CButton>
          <CButton @click="OfferModel = false" color="light">{{ $t('Global.Cancel') }}</CButton>
          <CButton id="OfferModelSubmit" @click="SaveOfferAmount()" color="success">
            {{ $t('Global.Confirm') }}
          </CButton>
        </footer>
      </template>
    </CModal>
  </main>
</template>

<route>
{
"meta": {
"label": "訂單明細"
}
}
</route>

<script>
import Editor from '@tinymce/tinymce-vue'
import TinyMCESetting from '@/plugins/tinymce'

export default {
  name: 'OrderNum',
  layout: 'manage',
  components: {
    MediaStore: () => import('@/components/public/Media'),
    OrderLog: () => import('@/components/order/Timeline'),
    Editor
  },
  filters: {
    Currency (value) {
      if (!value) value = 0
      return '$' + value.toString()
    }
  },
  data () {
    return {
      TinyMCE: TinyMCESetting,
      Accordion: false,
      LogisticsPackageAccordion: false,
      AdditionalReceiverAccordion: false,
      Features: [],
      LogisticsFeatures: [],
      NotifyFeatures: [],
      ToggleModal: false,
      MessageType: '',
      Component: null,
      SelectOrderNum: '',
      Data: {
        Amount: 0,
        CreateTime: '',
        Currency: '',
        CurrencyValue: 1,
        Invoice: {
          Type: '',
          Carrier: '',
          Header: '',
          Number: '',
          UniCode: ''
        },
        LogisticsData: {},
        Logistics: '',
        MemberInfo: {},
        Note: '',
        Offer: [],
        OrderNum: '',
        Payment: '',
        ProductInfo: [],
        RealAmount: 0,
        Status: {
          Payment: 'P',
          Order: 'P',
          Logistics: 'P'
        },
        Taxonomy: '',
        TotalAmount: 0,
        UpdateTime: '',
        UserID: ''
      },
      OfferModel: false,
      ShowOffer: 'All',
      Edit: {
        Payment: false,
        Logistics: false,
        BuyerInfo: false,
        Info: false,
        ProductInfo: false,
        Message: false,
        Invoice: false,
      },
      UpdateData: {
        BuyerInfo: {},
        Payment: {},
        Logistics: {},
        Info: {},
        ProductInfo: {},
        Message: {},
        Invoice: {},
        Offer: {
          Offer: []
        }
      },
      ChooseProductType: null,
      PaymentList: [],
      PaymentListOptions: [],
      LogisticsList: [],
      LogisticsListOptions: [],
      Loading: true,
      Submit: false,
      StatusClassOption: {
        'P': 'warning',
        'A': 'primary',
        'U': 'warning',
        'C': 'secondary',
        'F': 'danger',
        'N': 'danger',
        'Y': 'success',
        'H': 'info',
        'S': 'primary',
        'O': 'info',
      },
    }
  },
  computed: {
    ProductListField () {
      return [
        { key: 'Cover', label: this.$t('Product/List.Cover') },
        { key: 'Name', label: this.$t('Product/List.Name') },
        { key: 'SeriesNum', label: this.$t('Product/List.SeriesNum') },
        { key: 'Quantity', label: this.$t('Order.ProductInfo.Quantity') },
        { key: 'Amount', label: this.$t('Order.ProductInfo.Amount') },
        { key: 'Action', label: '' }
      ]
    },
    OfferListField () {
      return [
        { key: 'Name', label: this.$t('Offer.ActivityName') },
        { key: 'Taxonomy', label: this.$t('Offer.Type') },
        { key: 'OfferAmount', label: this.$t('Offer.DiscountValue') },
        { key: 'Action', label: '' }
      ]
    },
    ProductPackageListField () {
      return [
        { key: 'Cover', label: this.$t('Product/List.Cover') },
        { key: 'Name', label: this.$t('Product/List.Name') },
        { key: 'SeriesNum', label: this.$t('Product/List.SeriesNum') },
        { key: 'Quantity', label: this.$t('Order.ProductInfo.Quantity') },
      ]
    },
    UnpackedProductItems () {
      const PackageData = this.Data.Packing.Packages
      if (!PackageData || PackageData.length === 0) {
        return []
      }
      return PackageData[PackageData.length - 1].UnfitItems
    },
    PaymentStatusList() {
      return Object.keys(this.$t('Order.PaymentStatusList')).map(status => {
        return {
          value: status,
          label: this.$t('Order.PaymentStatusList')[status]
        }
      })
    },
    LogisticsStatusList() {
      return Object.keys(this.$t('Order.LogisticsStatusList')).map(status => {
        return {
          value: status,
          label: this.$t('Order.LogisticsStatusList')[status]
        }
      })
    },
    OrderStatusList() {
      return Object.keys(this.$t('Order.OrderStatusList')).map(status => {
        return {
          value: status,
          label: this.$t('Order.OrderStatusList')[status]
        }
      })
    },
    InvoiceTypeOptions() {
      return Object.keys(this.$t('Order.InvoiceType')).map(type => {
        return {
          value: type,
          label: this.$t('Order.InvoiceType')[type]
        }
      })
    },
    // CurrentUpdateOffer() {
    //   return this.UpdateData.Offer.Offer || []
    // }
  },
  created() {
    document.title = `${this.$t('Order.Info')} #${this.$route.params.orderNum}`
    const PermissionSession = JSON.parse(localStorage.getItem('Permission'))
    this.Features = PermissionSession.Features.Order || []
    this.LogisticsFeatures = PermissionSession.Features.System.Logistics || []
    this.NotifyFeatures = PermissionSession.Features.Notify || []
  },
  mounted() {
    this.$Progress.start()
    this.Init().then(() => {
      this.$Progress.finish()
    }).catch((err) => {
      this.$Progress.fail()
      this.$notify({
        group: 'notify',
        title: this.$t('Message.Error'),
        text: this.$t('Message.Global/ErrorMessage') + err.msg,
        type: (err.type ? err.type : 'error')
      })
    })
  },
  methods: {
    Init() {
      return Promise.all([
        this.GerOrder()
      ]).then(() => {
        return true
      }).catch((err) => {
        throw err
      })
    },
    GerOrder() {
      return this.$store.dispatch('InnerRequest', {
        url: '/order/detail',
        method: 'get',
        params: {
          orderNum: this.$route.params.orderNum
        }
      }).then(({data}) => {
        this.Loading = false
        this.Data = data.detail
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    EditData(type) {
      this.Edit[type] = true
      switch (type) {
        case 'ProductInfo':
          this.UpdateData[type].ProductInfo = this.Data.ProductInfo
          this.UpdateData[type]['Variable.SKU_Info'] = this.Data.Variable.SKU_Info || []
          break
        case 'Logistics':
          this.GetShipmentList()
          this.UpdateData[type]['Status.Logistics'] = this.Data.Status.Logistics
          this.UpdateData[type]['Logistics.ID'] = this.Data.Logistics.ID
          this.UpdateData[type]['Logistics.Name'] = this.Data.Logistics.Name
          this.UpdateData[type]['Logistics.System'] = this.Data.Logistics.System
          this.UpdateData[type]['Logistics.Method'] = this.Data.Logistics.Method
          this.UpdateData[type]['Logistics.Type'] = this.Data.Logistics.Type
          this.UpdateData[type]['Logistics.Fee'] = this.Data.Logistics.Fee
          this.UpdateData[type]['MemberInfo.ReceiverName'] = this.Data.MemberInfo.ReceiverName
          this.UpdateData[type]['MemberInfo.ReceiverPhone'] = this.Data.MemberInfo.ReceiverPhone
          this.UpdateData[type]['MemberInfo.ReceiverEmail'] = this.Data.MemberInfo.ReceiverEmail
          if (this.Data.Logistics.Type === 'EXPRESS') {
            this.UpdateData[type]['MemberInfo.ReceiverCity'] = this.Data.MemberInfo.ReceiverCity
            this.UpdateData[type]['MemberInfo.ReceiverArea'] = this.Data.MemberInfo.ReceiverArea
            this.UpdateData[type]['MemberInfo.ReceiverAddress'] = this.Data.MemberInfo.ReceiverAddress
            this.UpdateData[type]['MemberInfo.ReceiverZipCode'] = this.Data.MemberInfo.ReceiverZipCode
            this.UpdateData[type]['MemberInfo.ReceiverAddress2'] = this.Data.MemberInfo.ReceiverAddress2
            this.UpdateData[type]['MemberInfo.ReceiverAddress3'] = this.Data.MemberInfo.ReceiverAddress3
          }
          if (this.Data.Logistics.Type === 'SUPERSTORE') {
            this.UpdateData[type]['Logistics.Detail.StoreID'] = this.Data.Logistics.Detail.StoreID
            this.UpdateData[type]['Logistics.Detail.StoreName'] = this.Data.Logistics.Detail.StoreName
          }
          break
        case 'BuyerInfo':
          this.UpdateData[type]['MemberInfo.Name'] = this.Data.MemberInfo.Name
          this.UpdateData[type]['MemberInfo.Phone'] = this.Data.MemberInfo.Phone
          this.UpdateData[type]['MemberInfo.Email'] = this.Data.MemberInfo.Email
          this.UpdateData[type]['MemberInfo.City'] = this.Data.MemberInfo.City
          this.UpdateData[type]['MemberInfo.Area'] = this.Data.MemberInfo.Area
          this.UpdateData[type]['MemberInfo.Address'] = this.Data.MemberInfo.Address
          break
        case 'Message':
          this.UpdateData[type]['CustomMessage'] = {
            Content: '',
            Manager: {
              Account: this.$store.state.user.data.email + `(${this.$store.state.user.data.displayName})`,
              Name: this.$store.state.user.data.displayName,
              Email: this.$store.state.user.data.email,
              Cover: this.$store.state.user.data.photoURL,
              Uid: this.$store.state.user.uid,
              Class: 'Manage'
            },
            Origin: 'Manage'
          }
          break
        case 'Info':
          this.UpdateData[type].Note = this.Data.Note
          this.UpdateData[type]['Variable.AdminNote'] = this.Data.Variable.AdminNote
          this.UpdateData[type]['Variable.ShipmentNote'] = this.Data.Variable.ShipmentNote
          this.UpdateData[type]['MemberInfo.ReceiverDate'] = this.Data.MemberInfo.ReceiverDate
          break
        case 'Payment':
          this.GetPaymentList()
          this.UpdateData[type]['Status.Payment'] = this.Data.Status.Payment
          this.UpdateData[type]['Payment.Fee'] = this.Data.Payment.Fee
          this.UpdateData[type]['Payment.ID'] = this.Data.Payment.ID
          this.UpdateData[type]['Payment.Name'] = this.Data.Payment.Name
          this.UpdateData[type]['Payment.System'] = this.Data.Payment.System
          this.UpdateData[type]['Payment.Method'] = this.Data.Payment.Method
          this.UpdateData[type]['Payment.Type'] = this.Data.Payment.Type
          break
        case 'Processed':
          this.UpdateData.Message['CustomMessage'] = undefined
          this.UpdateData.Message['Variable.MessageProcessed'] = !this.Data.Variable.MessageProcessed
          this.Update('Message')
          break
        case 'Invoice':
          this.UpdateData[type]['Invoice.Type'] = this.Data.Invoice.Type
          this.UpdateData[type]['Invoice.Carrier'] = this.Data.Invoice.Carrier
          this.UpdateData[type]['Invoice.Header'] = this.Data.Invoice.Header
          this.UpdateData[type]['Invoice.UniCode'] = this.Data.Invoice.UniCode
          this.UpdateData[type]['Invoice.Address'] = this.Data.Invoice.Address
          break;
        case 'Offer':
          this.UpdateData[type].Offer = this.Data.Offer
          break
      }
    },
    Update(type) {
      if (this.Submit === true) {
        return Promise.resolve(true)
      }
      this.Submit = true
      this.$Progress.start()
      return this.$store.dispatch('InnerRequest', {
        url: '/order/edit',
        method: 'post',
        data: {
          orderNum: this.Data.OrderNum,
          origin: 'Manage',
          updateData: this.UpdateData[type]
        }
      }).then(() => {
        this.Edit[type] = false
        this.Submit = false
        this.$Progress.finish()
        this.GerOrder()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.Order/OrderChangeSuccess'),
          type: 'success'
        })
      }).catch((error) => {
        this.Submit = false
        this.Edit[type] = false
        this.$Progress.fail()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Order/OrderChangeFail') + error.Message,
          type: (error.type ? error.type : 'error')
        })
      })
    },
    CheckInvoiceType () {
      if (this.UpdateData.Invoice['Invoice.Type'] === 'INVALID') {
        this.UpdateData.Invoice['Invoice.Number'] = ''
        this.UpdateData.Invoice['Invoice.RandomNumber'] = ''
        this.UpdateData.Invoice['Invoice.IssueDate'] = ''
        this.UpdateData.Invoice['Invoice.RelateNumber'] = ''
      }
    },
    OpenCreateLogisticsTrackingModal() {
      this.Component = () => import('@/components/order/CreateLogisticsTracking')
      setTimeout(() => {
        this.SelectOrderNum = this.Data.OrderNum
        this.ToggleModal = true
      }, 300)
    },
    OpenProductListModal(taxonomy) {
      this.EditData('ProductInfo')
      this.ChooseProductType = taxonomy
      this.Component = () => import('@/components/product/OrderProductList')
      setTimeout(() => {
        this.ToggleModal = true
      }, 300)
    },
    OpenNotifyModal (messageType) {
      this.MessageType = messageType
      this.Component = () => import('@/components/order/SendNotify')
      setTimeout(() => {
        this.ToggleModal = true
      }, 300)
    },
    OpenSMSModal (messageType) {
      this.MessageType = messageType
      this.Component = () => import('@/components/order/SendSMS')
      setTimeout(() => {
        this.ToggleModal = true
      }, 300)
    },
    ChooseProduct(items) {
      this.$set(this.Comment, 'Detail', items[0])
      this.Comment.SeriesNum = items[0].SeriesNum
      this.Comment.CustomSeriesNum = items[0].CustomSeriesNum
    },
    IssueInvoice () {
      if (this.Data.Status.Payment !== 'Y') {
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Warning'),
          text: this.$t('Message.Order/NeedPaymentToIssue'),
          type: 'warn',
          duration: 10000
        })
        return false
      }
      return this.$swal({
        icon: 'info',
        title: this.$t('Message.Order/QuestionIssueInvoice'),
        text: this.$t('Message.Order/QuestionIssueInvoiceDescription'),
        showCancelButton: true,
        confirmButtonColor: '#2eb85c',
        confirmButtonText: this.$t('Global.Confirm'),
        cancelButtonText: this.$t('Global.Cancel'),
      }).then((result) => {
        if (result.isConfirmed) {
          this.$Progress.start()
          return this.$store.dispatch('InnerRequest', {
            url: '/order/issueInvoice',
            method: 'post',
            data: {
              OrderData: {
                OrderNum: this.Data.OrderNum,
                UserID: this.Data.UserID,
                Variable: this.Data.Variable,
                Wallet: this.Data.Wallet,
                TotalAmount: this.Data.TotalAmount,
                RealAmount: this.Data.RealAmount,
                OfferAmount: this.Data.OfferAmount,
                Amount: this.Data.Amount,
                Payment: this.Data.Payment,
                Logistics: this.Data.Logistics,
                Invoice: this.Data.Invoice,
                MemberInfo: this.Data.MemberInfo,
                ProductInfo: this.Data.ProductInfo
              }
            }
          }).then(() => {
            this.$Progress.finish()
            this.GerOrder()
            this.$notify({
              group: 'notify',
              title: this.$t('Message.Success'),
              text: this.$t('Message.Order/IssueInvoiceSuccess'),
              type: 'success'
            })
          }).catch((error) => {
            this.$Progress.fail()
            this.$notify({
              group: 'notify',
              title: this.$t('Message.Error'),
              text: this.$t('Message.Order/IssueInvoiceFail') + error.msg,
              type: (error.type ? error.type : 'error')
            })
          })
        }
      })
    },
    VoidInvoice () {},
    GetPaymentList() {
      return this.$store.dispatch('InnerRequest', {
        url: '/system/payment/list',
        method: 'get'
      }).then(({data}) => {
        this.PaymentList = Object.keys(data).map((id) => {
          return {
            ...data[id],
            ID: id,
            System: data[id].System.toUpperCase()
          }
        })
        this.PaymentListOptions = this.PaymentList.map((item) => {
          return {
            label: `${item.Name} (${this.$t('Order.PaymentType')}: ${this.$t('Payment.PaymentMethods.' + item.Method)}，${this.$t('Order.PaymentSystem')}: ${this.$t('Payment.PaymentSystem.' + item.System)}，${this.$t('Payment.Fee')}: ${item.Fee || 0})`,
            value: item.ID
          }
        })
      }).catch((err) => {
        throw err
      })
    },
    GetShipmentList() {
      return this.$store.dispatch('InnerRequest', {
        url: '/system/logistics/list',
        method: 'get'
      }).then(({data}) => {
        this.LogisticsList = Object.keys(data).map((id) => {
          return {
            ...data[id],
            ID: id,
            System: data[id].System.toUpperCase()
          }
        })
        this.LogisticsListOptions = this.LogisticsList.map((item) => {
          return {
            label: `${item.Name} (${this.$t('Order.LogisticsType')}: ${this.$t('Logistics.LogisticsMethods.' + item.Method)}，${this.$t('Order.LogisticsSystem')}: ${this.$t('Logistics.LogisticsSystem.' + item.System)}，${this.$t('Logistics.Fee')}: ${item.Fee}，${this.$t('Logistics.ServiceLocation')}: ${this.$t('Logistics.ServiceLocationOptions.' + item.ServiceLocation)})`,
            value: item.ID
          }
        })
      }).catch((err) => {
        throw err
      })
    },
    ChangePayment () {
      const NewPayment = this.PaymentList.find((item) => item.ID === this.Data.Payment.ID)
      if (NewPayment) {
        this.UpdateData.Payment['Payment.ID'] = NewPayment.ID
        this.UpdateData.Payment['Payment.Name'] = NewPayment.Name
        this.UpdateData.Payment['Payment.System'] = NewPayment.System
        this.UpdateData.Payment['Payment.Method'] = NewPayment.Method
        this.UpdateData.Payment['Payment.Type'] = NewPayment.Type
      }
    },
    ChangeLogistics () {
      const NewLogistics = this.LogisticsList.find((item) => item.ID === this.Data.Logistics.ID)
      if (NewLogistics) {
        this.UpdateData.Logistics['Logistics.ID'] = NewLogistics.ID
        this.UpdateData.Logistics['Logistics.Name'] = NewLogistics.Name
        this.UpdateData.Logistics['Logistics.System'] = NewLogistics.System
        this.UpdateData.Logistics['Logistics.Method'] = NewLogistics.Method
        this.UpdateData.Logistics['Logistics.Type'] = NewLogistics.Type
      }
    },
    OpenOfferModal() {
      this.EditData('Offer')
      this.OfferModel = true
    },
    AddOffer() {
      this.UpdateData.Offer.Offer.push({
        Name: '折扣',
        Taxonomy: 'CUSTOM',
        CurrentOfferAmount: 0,
        Editing: false
      })
    },
    SaveOfferAmount () {
      this.UpdateData.Offer.OfferAmount = this.UpdateData.Offer.Offer.reduce((acc, offer) => acc + parseInt(offer.CurrentOfferAmount || '0'), 0)
      this.UpdateData.Offer.RealAmount = this.Data.Amount - this.UpdateData.Offer.OfferAmount - (this.Data.Wallet?.Bonus?.Value || 0)
      this.UpdateData.Offer.TotalAmount = this.Data.Amount - this.UpdateData.Offer.OfferAmount + this.Data.Logistics.Fee + this.Data.Payment.Fee - (this.Data.Wallet?.Bonus?.Value || 0)
      return this.Update('Offer').then(() => {
        this.OfferModel = false
      })
    },
    UpdateProductInfo (value) {
      if (value) {
        this.UpdateData.ProductInfo.ProductInfo.push(value)
        typeof value.SKU === 'object' && this.UpdateData.ProductInfo['Variable.SKU_Info'].push({
          ...value.SKU,
          Quantity: parseInt(value.Quantity || '1')
        })
      }
      this.UpdateData.ProductInfo.ProductInfo = this.UpdateData.ProductInfo.ProductInfo.map(item => {
        item.Quantity = parseInt(item.Quantity || '0')
        item.Amount.Actual = parseInt(item.Amount.Actual || '0')
        return item
      })
      this.UpdateData.ProductInfo.Amount = this.UpdateData.ProductInfo.ProductInfo.map(item => Math.round(item.Amount?.Actual * item.Discount) * item.Quantity || 0).reduce((a, c) => a + c, 0)
      this.UpdateData.ProductInfo.RealAmount = this.UpdateData.ProductInfo.Amount - this.Data.OfferAmount - (this.Data.Wallet?.Bonus?.Value || 0)
      this.UpdateData.ProductInfo.TotalAmount = this.UpdateData.ProductInfo.Amount - this.Data.OfferAmount + this.Data.Logistics.Fee + this.Data.Payment.Fee - (this.Data.Wallet?.Bonus?.Value || 0)

      this.Edit.ProductInfo = false
      return this.Update('ProductInfo')
    },
    UpdateLogistics () {
      this.UpdateData.Logistics.TotalAmount = this.Data.Amount - this.Data.OfferAmount + parseInt(this.UpdateData.Logistics['Logistics.Fee']) + this.Data.Payment.Fee - (this.Data.Wallet?.Bonus?.Value || 0)
      return this.Update('Logistics')
    },
    UpdatePayment () {
      this.UpdateData.Payment.TotalAmount = this.Data.Amount - this.Data.OfferAmount + this.Data.Logistics.Fee + parseInt(this.UpdateData.Payment['Payment.Fee']) - (this.Data.Wallet?.Bonus?.Value || 0)
      return this.Update('Payment')
    },
    Copy(_link) {
      if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.Global/CopySuccess'),
          type: 'success'
        })
        return navigator.clipboard.writeText(_link)
      }
      return Promise.reject('The Clipboard API is not available.');
    },
    DeleteProductItem (index) {
      this.EditData('ProductInfo')
      return this.$swal({
        icon: 'info',
        title: '確認移除',
        text: '此動作無法還原，是否移除此商品？',
        showCancelButton: true,
        confirmButtonColor: '#e55353',
        confirmButtonText: this.$t('Global.Confirm'),
        cancelButtonText: this.$t('Global.Cancel'),
      }).then((result) => {
        this.Edit.ProductInfo = false
        if (result.isConfirmed) {
          this.UpdateData.ProductInfo.ProductInfo.splice(index, 1)
          this.UpdateProductInfo()
        }
      })
    },
    RemoveMessage(index) {
      this.Data.Message.splice(index, 1)
      if (this.Submit === true) {
        return Promise.resolve(true)
      }
      this.Submit = true
      this.$Progress.start()
      return this.$store.dispatch('InnerRequest', {
        url: '/order/edit',
        method: 'post',
        data: {
          orderNum: this.Data.OrderNum,
          origin: 'Manage',
          updateData: {
            Message: this.Data.Message
          }
        }
      }).then(() => {
        this.Submit = false
        this.$Progress.finish()
        this.GerOrder()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.Order/OrderChangeSuccess'),
          type: 'success'
        })
      }).catch((error) => {
        this.Submit = false
        this.$Progress.fail()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Order/OrderChangeFail') + error.Message,
          type: (error.type ? error.type : 'error')
        })
      })
    }
  }
}
</script>

<style lang="scss">
.table {
  thead th {
    white-space: nowrap;
    &:first-child {
      width: 100px;
    }
  }
  td {
    &:first-child {
      white-space: nowrap;
    }
  }
}
</style>

<style lang="scss" scoped>
.mt-70 {
  margin-top: 70px
}

.mb-70 {
  margin-bottom: 70px
}

.vertical-timeline {
  width: 100%;
  position: relative;
  padding: 1.5rem 0 1rem
}

.vertical-timeline::before {
  content: '';
  position: absolute;
  top: 0;
  left: 67px;
  height: 100%;
  width: 4px;
  background: #e9ecef;
  border-radius: .25rem
}

.vertical-timeline-element {
  position: relative;
  margin: 0 0 1rem
}

.vertical-timeline--animate .vertical-timeline-element-icon.bounce-in {
  visibility: visible;
  animation: cd-bounce-1 .8s
}

.vertical-timeline-element-icon {
  position: absolute;
  top: 50%;
  left: 60px;
  transform: translateY(-50%);
}

.vertical-timeline-element-icon .badge-dot-xl {
  box-shadow: 0 0 0 5px #fff
}

.badge-dot-xl {
  width: 18px;
  height: 18px;
  position: relative
}

.badge:empty {
  display: inline-block
}

.badge-dot-xl::before {
  content: '';
  width: 10px;
  height: 10px;
  border-radius: .25rem;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -5px 0 0 -5px;
  background: #fff
}

.vertical-timeline-element-content {
  position: relative;
  margin-left: 90px;
}

.vertical-timeline-element-content .timeline-title {
  text-transform: uppercase;
  margin: 0 0 .5rem;
  font-weight: bold
}

.vertical-timeline-element-content .vertical-timeline-element-date {
  display: block;
  position: absolute;
  left: -90px;
  top: 48%;
  transform: translateY(-48%);
  padding-right: 10px;
  text-align: right;
  color: #adb5bd;
  font-size: .7619rem;
  white-space: nowrap
}

.vertical-timeline-element-content:after {
  content: "";
  display: table;
  clear: both
}

.MessageContent {
  max-height: 400px;
  overflow: auto;
}
</style>
